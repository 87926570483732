import React, { useContext, useEffect, useState } from 'react'
import { Note } from '../../stickyGrid/note'
import { ProjectContext } from '../../../../context/ProjectContext'
// import { StarsFilter } from './filters/stars'
// import { TagsFilter } from './filters/tags'
// import { MeetingsFilter } from './filters/meetings'
// import { CategoriesFilter } from './filters/categories'

export function Needs () {
  const { notes } = useContext(ProjectContext)
  const [filteredNotes, setFilteredNotes] = useState([])

  useEffect(() => {
    if (notes.length) {
      const filtered = notes.filter(note => note.star).sort((a, b) => a.start_time - b.start_time)
      if (filtered.length > 12) {
        setFilteredNotes(filtered.slice(0, 12))
      } else {
        setFilteredNotes(filtered)
      }
    }
  }, [])

  return (
    <>
      <p className="mb-4 w-full pb-[10px] text-[#40424A] text-[26px] font-semibold font-['Manrope'] leading-[29.90px] border-b-2 border-grey-200">Needs</p>
      {/* <div className="h-12 bg-white rounded-[10px] flex item-center" style={{ boxShadow: '0px 0px 16px 8px rgba(0, 0, 0, 0.15)' }}>
        <StarsFilter />
        <TagsFilter />
        <MeetingsFilter />
        <CategoriesFilter />
      </div> */}

      {/* <div className='w-full flex flex-wrap pt-5 relative' ref={notesRef} onClick={() => {
        setSelectedNotes([])
        setFirstNoteSelected(null)
        setNoteHover(null)
      }}> */}
      <div className='w-full flex flex-wrap ml-[44px] relative'>
        {filteredNotes.map(note =>
          <Note
            key={note.id}
            meetingId={note.meeting_id}
            note={note}
            selected={false}
            handleNoteSelect={() => {}}
            noteHover={''}
            setNoteHover={() => {}}
          />
        )}
      </div>
    </>
  )
}
